<template>
  <section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card card-block card-stretch card-height">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                    <h4 class="card-title">Vistas por Dia</h4>
                    </div>
                </div>
                <div class="card-body w-100">
                    <ApexChart class="w-100" element="yearly-chart-02" :chartOption="chart2" />
                </div>
                </div>
            </div>
            <div class="row w-100">
                <div class="col-6">
                    <div class="row p-0 m-0">   
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                                            <path
                                            d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Sessões</h5>
                                            <h3>2345</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Utilizadores</h5>
                                            <h3>4</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Vistas de página</h5>
                                            <h3>7841</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Páginas / Sessão</h5>
                                            <h3>3.3</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Duração média da sessão</h5>
                                            <h3>00:07:24</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card card-block card-stretch back-img">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <div class="align-items-center card-total-sale">
                                        <div class="border bg-warning rounded iq-icon-box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            height="25"
                                            width="25"
                                            class="mb-1"
                                        >
                                            <path
                                            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                                            />
                                        </svg>
                                        </div>
                                        <div
                                        class="d-flex align-items-center card-total-sale justify-content-between mt-3"
                                        >
                                        <div class="card-text">
                                            <h5>Novas Sessões</h5>
                                            <h3>7</h3>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 p-0">  
                    <div class="card card-block card-stretch card-height">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                            <h4 class="card-title">Vistas por país</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <ApexChart element="like-chart-05" :chartOption="chart4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import ApexChart from "../../../../components/charts/ApexChart";
export default {
    components: {
        ApexChart,
    },
    data(){
        return{
            chart2: {
                series: [
                {
                    name: "Annual forecast",
                    data: [60, 40, 90, 70, 50, 60, 60, 88, 90],
                }
                ],
                colors: ["#F56692", "#ff6b6b"],
                chart: {
                height: 275,
                type: "line",
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false,
                },
                },
                dataLabels: {
                enabled: false,
                },
                stroke: {
                curve: "smooth",
                width: 3,
                },
                title: {
                text: "",
                align: "left",
                },

                grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0,
                },
                },
                xaxis: {
                categories: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep",],
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                },
                },
                yaxis: {
                labels: {
                    offsetY: 0,
                    minWidth: 22,
                    maxWidth: 22,

                    // formatter: function (val) {
                    // return "€" + val;
                    // },
                },
                },
                legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: -33,
                }
            },
            chart4: {
                series: [6141, 700, 648, 352],
                chart: {
                height: 300,
                type: "donut",
                },
                labels: ["Portugal", "Estados Unidos", "Espanha", "Outros"],
                colors: ["#F56692", "#04237D", "#F9756A", "#697A21"],
                plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                },
                },
                dataLabels: {
                enabled: false,
                },

                legend: {
                position: "top",
                },
                grid: {
                padding: {
                    top: 30,
                },
                },

                responsive: [
                {
                    breakpoint: 480,
                    options: {
                    chart: {
                        height: 300,
                    },
                    },
                },
                ],
            },
        }
    }
}
</script>

<style scoped>
.back-img:before{
    display: none;
}
.back-img:after{
    display: none;
}
.card-block.card-stretch{
    align-items: center;
    justify-content: center;
}
.card-body{
    display: flex;
    align-items: center;
}
</style>